import React from 'react'
import { Helmet } from 'react-helmet'
// import { Link } from 'gatsby'

import SalesBoost from '../../components/contact-boost'
import Layout from '../../layout/layout'
import WatchVideo from '../../components/watch-video'

import ShoppingArea from '../../svg/shopping-area.svg'
import Hand from '../../svg/hand.svg'
import Discover from '../../svg/discover.svg'
import RetailerApp from '../../svg/retailer-app.svg'
import WatchVideoWithPlaceholder from '../../components/watch-video-with-placeholder'

const Page = () => (

    <Layout>

        <Helmet>
          <title>Winkelgebieden - Uw winkelgebied online</title>
          <meta name="description" content="Met Zupr laat u consumenten online zien waar producten in lokale, fysieke winkels verkrijgbaar zijn." />
        </Helmet>

        <section className="city shopping-area">
            <h1>Alles uit de buurt online te vinden</h1>
            <WatchVideo id="abRyUwyTVlE">
                Bekijk video
            </WatchVideo>
            <a className="btn btn-demo" href="mailto:demo@zupr.io?subject=Ik wil graag een demo">Vraag demo aan</a>
            <div className="background">
                <ShoppingArea />
            </div>
        </section>

        <section className="text-left hand divider">
            <div className="inner">
                <Hand />
                <div className="text">
                    <h2>Online zoeken, lokaal kopen</h2>
                    <p>Met uw eigen collectieve retailplatform maakt u het aanbod van uw lokale winkels online zichtbaar. Bijvoorbeeld als stad of als winkelcentrum.  Consumenten die zich online oriënteren, kunnen dan direct zien of er lokale winkels zijn die het gezochte product op voorraad hebben. De consument kan het product dan kopen in de lokale winkel in plaats van online en daarmee versterkt u de positie van lokale retailers.</p>
                </div>
            </div>
        </section>

        <section className="text-right divider">
            <div className="inner">

                <div className="image">
                    <WatchVideoWithPlaceholder id="t-76qDy2Y1w" image="zoom-with-zupr.png">
                    </WatchVideoWithPlaceholder>
                </div>
                <div className="text">
                    <h2>Hogere omzet in winkelgebieden</h2>
                    <p>Consumenten zoeken steeds vaker online waar producten in de buurt te koop zijn. De laatste twee jaar is die behoefte zelfs met 500% gegroeid! Maar omdat het assortiment van lokale winkels vaak slecht (of helemaal niet) online te vinden is, vertaalt zich dit niet in meer verkopen. Lokale winkels zullen alleen profiteren als de online zichtbaarheid van wat ze verkopen toeneemt. Met het collectief retailplatform van Zupr kan online vraag moeiteloos worden gekoppeld aan lokaal aanbod.</p><p>Bekijk de video om te horen hoe klanten het gebruik van het collectief retailplatform ervaren.</p>
                </div>
            </div>
        </section>

        <section className="text-center zupr-partners">
            <div className="inner">
                <div className="text">
                    <h3>Een greep uit onze klanten</h3>
                </div>
                <ul>
                    <li className="groningen-city-club"></li>
                    <li className="hartje-shertogenbosch"></li>
                    <li className="de-gijsbrecht"></li>
                    <li className="bizzy"></li>
                </ul>
            </div>
        </section>

        <section className="text-center grey discover">
            <div className="inner">

                <div className="text">
                    <h2>“Warenhuis” van de toekomst</h2>
                    <p>Het voordeel van een collectief retailplatform van Zupr is dat alle producten doorzoekbaar zijn zoals bij de grote webshops en online marktplaatsen. Dus op naam of merk, maar bijvoorbeeld ook op exacte maat of kleur. Al het gemak van online, maar dan gecombineerd met de voordelen van lokaal. Op die manier kunnen lokale winkeliers serieus terrein terugwinnen op pure online spelers. Het fysieke winkelgebied als online warenhuis van de toekomst!</p>
                </div>

                <div className="image">
                    <Discover />
                </div>
                <div className="text">
                    <p>Bekijk ook onze <a href="/nl/over-ons">roadmap</a> welke nieuwe functionaliteit we binnenkort gaan toevoegen, zoals ondersteuning voor uw eigen <strong>stadspassen / cadeakaart</strong> en de kaartweergave voor een volledig beeld van alles <strong>winkels èn horeca</strong>!</p>
                </div>
            </div>
        </section>

        <section className="text-left divider">
            <div className="inner">

                <div className="text">
                    <h2>Eenvoudig in gebruik voor retailers</h2>
                    <p>In tegenstelling tot veel andere retailplatformen is Zupr voor iedere retailer toegankelijk. Van de kleine retailer op de hoek tot de grootste winkelketens. Voor kleine retailers is een <a href="/nl/retailers">smartphone app</a> ontwikkeld waarmee ze binnen 15 minuten hun verkooplocatie en producten kunnen aanmelden. Gewoon door producten in de winkel te scannen. Ze zijn dan direct online te vinden via alle kanalen die Zupr bediend. Want alleen als iedereen meedoet, wint de lokale retail!</p>
                </div>

                <div className="image">
                    <RetailerApp />
                </div>

            </div>
        </section>

        <section className="text-center divider">
            <div className="inner">

                <div className="text">
                    <h2>Voor wie?</h2>
                    <p>Vertegenwoordigt u een stad, winkelstraat of winkelcentrum? Dan is Zupr, naast een collectief retailplatform in uw eigen huisstijl, ook te integreren in uw bestaande online kanalen. Bijvoorbeeld in de website van uw stad of de app van uw winkelcentrum. De consument kan dan per winkel op productniveau zien welke producten ze verkopen.</p>
                    <p>Heeft u er belang bij dat meer consumenten uw lokale winkels bezoeken en dat de omzetten toenemen? Bijvoorbeeld om de leefbaarheid en werkgelegenheid op peil te houden of om de hoogte van de huren van uw winkelvastgoed te bestendigen? Neem dan contact met ons op voor een vrijblijvende demo!</p>
                </div>

            </div>
        </section>

        <SalesBoost />

    </Layout>
)

export default Page
