import React, { useState } from 'react'
import Modal from 'react-modal'

import '../scss/components/modal.scss'
import Play from '../svg/play.svg'
import Close from '../svg/close.svg'

import MeetWithZupr from '../static/images/meet-with-zupr.png'
import ZoomWithZupr from '../static/images/zoom-with-zupr.png'


const Page =  ({ image, id }) => {
    let BgImage = MeetWithZupr
    if (image == 'meet-with-zupr.png') BgImage = MeetWithZupr
    if (image == 'zoom-with-zupr.png') BgImage = ZoomWithZupr

    const [modal, setModal] = useState(false)
    return (
        <div className="video-with-placeholder">
            <Modal
                isOpen={modal}
                overlayClassName="zupr-modal-overlay"
                className="zupr-modal zupr-modal-video"
                onRequestClose={() => setModal(false)}
                closeTimeoutMS={300}
            >
                <iframe width="100%" height="100%" src={`https://www.youtube.com/embed/${id}?rel=0&amp;showinfo=0`} frameBorder="0" allow="autoplay; encrypted-media" title="Zupr" allowFullScreen></iframe>

                <button className="modal-close" onClick={() => setModal(false)}>
                    <Close />
                </button>
            </Modal>

            <a className="view-video" onClick={() => setModal(true)}>
                <div className="btn-play">
                    <Play />
                </div>
                <img width="100%" src={BgImage} />
            </a>
        </div>
    )
}

export default Page
